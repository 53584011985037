<template>

	<div class="page">

		<div class="top">
			<div class="add-patient" @click="toAdd">新增患者</div>
		</div>
		<div class="search-result">
			<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
				<van-list v-model="loading" :finished="finished" :finished-text="finishtext" @load="onLoad">
					<template v-for="(item, index) in list" >
						<patient-item @click.native="jumpPatientMatchList(item.id)" @delElem="delElem" :status="item.status" :index="index" :id="item.id" :name="item.name" :age="item.age" :ill="item.illType" :sex="item.sex" :key="index"></patient-item>
					</template>

				</van-list>
			</van-pull-refresh>
		</div>
	</div>

</template>

<script>
	import {
		PullRefresh,
		List
	} from 'vant';
	import patientItem from '@/components/patient/patient-item';
	import patientApi from '@/api/patient/patientApi.js';
	import {toPath} from '@/utils/common'
	export default {
		components: {
			[PullRefresh.name]: PullRefresh,
			[List.name]: List,
			patientItem,
		},
		data() {
			return {
				pageCount: 0,
				pageNo: 0,
				isShowProject: true,
				list: [],
				loading: false,
				finished: false,
				finishtext: '没有更多了',
				isLoading: false, //控制下拉刷新的加载动画
			}
		},
		methods: {
			jumpPatientMatchList(inquiryId){
				// this.$router.push({
				// 	path: '/patientMatchList',
				// 	query:{
				// 		inquiryId:inquiryId
				// 	}
				// })
				toPath(this, {
                    path: '/patientMatchList',
                    query: { inquiryId }
                },`/pages/match/index/index?inquiryId=${inquiryId}`)
			},
			delElem(index){
				this.list.splice(index, 1);
			},
			onLoad() {
				this.pageNo++;
				if (this.pageNo > 1 && this.pageNo > this.pageCount) {
					this.loading = false;
					this.finished = true;
					return;
				}
				this.getList();

			},
			//下拉刷新
			onRefresh() {
				Object.assign(this, {
					pageCount: 0,
					pageNo: 1,
					list: []
				})
				this.getList(); //加载数据
			},
			//获取列表
			getList() {
				patientApi.patientList({
					pageNo: this.pageNo
				}).then(rs => {

					this.list=this.list.concat(rs.data.list);
					//console.log(rs.data);
					this.finishLoad(rs)
				}).catch(() => {
					this.finishLoad();
				});
			},
			
			finishLoad(rs) {
				this.isLoading = false;
				this.loading = false;
				if(!rs){
					this.finished = true;
					return;
				}
				this.pageNo = rs.data.pageNo;
				this.pageCount = rs.data.pageCount;
				if (this.pageNo < this.pageCount) {
					this.finished = false;
				} else {
					this.finished = true;
					if (this.list.length > 0) {
						this.finishtext = '没有更多了';
					
					} else {
						this.finishtext = '暂无数据';
					}
				}
			},
			toAdd() {
				this.$router.push({
					path: '/crePatientBeforeAnswer',
					query:{
						type:1
					}
				})
			},

		}

	}
</script>

<style lang="less" scoped>
	.page {
		min-height: 100vh;
		background-color: #F7F7F7;

		.top {
			display: flex;
			flex-direction: row-reverse;
			padding: 15px 15px 7px;

			.add-patient {
				width: auto;
				height: auto;
				background: rgba(0, 96, 194, 1);
				font-size: 15px;
				font-weight: 500;
				color: rgba(255, 255, 255, 1);
				padding: 4px 9px;
			}
		}

	}

	.search-result {
		padding-bottom: 8px;

		.search-result-count {
			font-size: 14px;
			color: rgba(130, 130, 132, 1);
		}

	}
</style>
